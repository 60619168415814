import React from "react"
import Layout from "../components/Layout"
import "../css/projects/pythonprojectcard.css"
import { Link } from "gatsby"
import Particle7 from "../components/Particle7"
import SEO from "../components/SEO"

const Pythonprojectcard = () => {
  return (
    <Layout>
      <SEO
        title="Python Projects"
        description="This Page is for Python Project Ocean."
      />
      <Particle7></Particle7>
      <h6>s</h6>
      <div className="programming-projectp">
        <h1>Python Programming Projects</h1>
      </div>

      <div className="favp noSelect">
        <h3>
          Those projects having a{" "}
          <span role="img" aria-label="Heart">
            ❤️
          </span>{" "}
          on them... come under the Favourite category.{" "}
        </h3>
      </div>

      <section className="most-likedp noSelect">
        <h3 className="totalp">Total Projects :- 35</h3>
        <div className="rowp">
          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">
                  Weather App
                  <span role="img" aria-label="Heart">
                    ❤️
                  </span>
                </h3>
              </div>
              <div className="card-img34p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Django-Weather-App"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>
                    <a
                      href="https://arpitsomani.herokuapp.com/"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Live
                    </a>

                    <Link to="/py_proj34/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">
                  Egg Catcher Game
                  <span role="img" aria-label="Heart">
                    ❤️
                  </span>
                </h3>
              </div>
              <div className="card-img1p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Egg%20Catcher%20Game"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj1/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">
                  GPS-Track The Route
                  <span role="img" aria-label="Heart">
                    ❤️
                  </span>
                </h3>
              </div>
              <div className="card-img2p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/GPS-Track%20The%20Route"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj2/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">
                  Dictionary
                  <span role="img" aria-label="Heart">
                    ❤️
                  </span>
                </h3>
              </div>
              <div className="card-img3p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Dictionary"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj3/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Page Rank-How does google work</h3>
              </div>
              <div className="card-img4p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Page%20Rank-How%20Does%20Google%20Work"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj4/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Data Compression</h3>
              </div>
              <div className="card-img5p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Data%20Compression"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj5/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Facebook Sentiment Analysis</h3>
              </div>
              <div className="card-img6p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Facebook%20Sentiment%20Analysis"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj6/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">
                  Image Processing-Enhance your image
                  <span role="img" aria-label="Heart">
                    ❤️
                  </span>
                </h3>
              </div>
              <div className="card-img7p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Image%20Processing-Enhance%20Your%20Image"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj7/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">
                  Browser Automation-Whatsapp
                  <span role="img" aria-label="Heart">
                    ❤️
                  </span>
                </h3>
              </div>
              <div className="card-img8p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Browser%20Automation-Whatsapp"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj8/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">
                  Your own Database System
                  <span role="img" aria-label="Heart">
                    ❤️
                  </span>
                </h3>
              </div>
              <div className="card-img9p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Your%20Own%20Database%20System"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj9/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">NLP-Author Stylometry</h3>
              </div>
              <div className="card-img10p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Natural%20Language%20Processing-Author%20Stylometry"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj10/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Caterpillar</h3>
              </div>
              <div className="card-img11p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Caterpillar"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj11/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Area Calculation-Don't Measure</h3>
              </div>
              <div className="card-img12p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Area%20Calculation-Don_t%20Measure"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj12/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">
                  Screen Pet
                  <span role="img" aria-label="Heart">
                    ❤️
                  </span>
                </h3>
              </div>
              <div className="card-img13p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Screen%20Pet"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj13/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">
                  FLAMES
                  <span role="img" aria-label="Heart">
                    ❤️
                  </span>
                </h3>
              </div>
              <div className="card-img14p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/FLAMES"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj14/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">
                  Monte Hall_3 Doors and a Twist
                  <span role="img" aria-label="Heart">
                    ❤️
                  </span>
                </h3>
              </div>
              <div className="card-img15p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Monte%20Hall_3%20Doors%20And%20a%20Twist"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj15/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Dobble Game</h3>
              </div>
              <div className="card-img16p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Dobble%20Game"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj16/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Jumbled Words</h3>
              </div>
              <div className="card-img17p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Jumbled%20Words"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj17/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Birthday Paradox</h3>
              </div>
              <div className="card-img18p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Birthday%20Paradox"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj18/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">
                  Guess the movie
                  <span role="img" aria-label="Heart">
                    ❤️
                  </span>
                </h3>
              </div>
              <div className="card-img19p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Guess%20The%20Movie%20Name"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj19/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">
                  Substituion Cipher-The Science of Secrecy
                  <span role="img" aria-label="Heart">
                    ❤️
                  </span>
                </h3>
              </div>
              <div className="card-img20p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Substitution%20Cipher-The%20Science%20of%20Secrecy"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj20/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Tic-tac-toe</h3>
              </div>
              <div className="card-img21p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Tic-Tac-Toe"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj21/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">
                  Magic Square
                  <span role="img" aria-label="Heart">
                    ❤️
                  </span>
                </h3>
              </div>
              <div className="card-img22p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Magic%20Square"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj22/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Lottery Simulation</h3>
              </div>
              <div className="card-img23p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Lottery%20Simulation"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj23/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">
                  Speech to Text
                  <span role="img" aria-label="Heart">
                    ❤️
                  </span>
                </h3>
              </div>
              <div className="card-img24p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Speech%20To%20Text-No%20Need%20To%20Write"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj24/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Rock Paper and Scissor</h3>
              </div>
              <div className="card-img25p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Rock%2C%20Paper%20And%20Scissor_Cheating%20Not%20Allowed"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj25/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Crowd Computing</h3>
              </div>
              <div className="card-img26p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Crowd%20Computing"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj26/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Snakes and Ladders</h3>
              </div>
              <div className="card-img27p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Snakes%20And%20Ladders"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj27/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Anagrams</h3>
              </div>
              <div className="card-img28p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Anagrams"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj28/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Six Degree of Separation</h3>
              </div>
              <div className="card-img29p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Six%20Degrees%20Of%20Separation"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj29/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Spiral Traversing</h3>
              </div>
              <div className="card-img30p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Spiral%20Traversing"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj30/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Fun with Calender</h3>
              </div>
              <div className="card-img31p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Fun%20With%20Calender"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj31/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Theory of Evolution</h3>
              </div>
              <div className="card-img32p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Theory%20of%20Evolution"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj32/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">MatchMaker</h3>
              </div>
              <div className="card-img33p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/MatchMaker"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj33/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rotatep">
            <div className="coffee-cardp">
              <div className="card-headerp">
                <h3 className="title-textp">Collatz Conjecture</h3>
              </div>
              <div className="card-img35p"></div>
              <div className="backp">
                <div className="back-contentp">
                  <div className="layer"></div>
                  <h4 className="textp">Checkout here...</h4>
                  <div className="bttn-boxp">
                    <a
                      href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Collatz%20Conjecture"
                      className="btnttp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Code
                    </a>

                    <Link to="/py_proj35/" className="btnttp">
                      More Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Pythonprojectcard
